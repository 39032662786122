class FooterController {

    private closeOfferAdvertisementBanner = () : void => {
        const bannerDiv = document.getElementsByClassName("js-footer-offer-banner-placeholder")[0];
        bannerDiv.classList.remove("footer-offer-banner-placeholder-open");
        bannerDiv.classList.add("footer-offer-banner-placeholder-close");
    
        const bannerPlaceholder = document.getElementsByClassName("js-footer-offer-banner")[0];
        bannerPlaceholder.classList.remove("banner-container-open");
        bannerPlaceholder.classList.add("banner-container-close");
    }

    public init = () : void => {
        // add banner close event
        [].map.call(document.getElementsByClassName("js-footer-offer-banner-close-button"),
            bannerCloseBnt => bannerCloseBnt.addEventListener("click", this.closeOfferAdvertisementBanner));
    }
}

new FooterController().init();